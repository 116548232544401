<template>
  <header class="hdr" :class="darkModeClass">
    <nav class="hdr__nv" :class="{ hdr__nv__active: open }">
      <router-link class="nav__el" to="/">
        <span class="sp">НАЧАЛО</span>
      </router-link>

      <router-link class="nav__el" to="/current-project">
        <span class="sp">ТЕКУЩ ПРОЕКТ</span>
      </router-link>

      <router-link class="nav__el--img" to="/">
        <img
          class="dark__logo"
          :class="darkModeClass"
          src="@/assets/img/logo.svg"
          alt="logo"
        />
        <img
          class="white__logo"
          :class="darkModeClass"
          src="@/assets/img/logo-w.svg"
          alt="logo"
        />
      </router-link>

      <router-link class="nav__el" to="/map">
        <span class="sp">ПЪТНА КАРТА</span>
      </router-link>

      <a class="nav__el" @click="showContacts = !showContacts">
        <span class="sp"> КОНТАКТИ</span>
      </a>
    </nav>

    <transition name="slide-fade" mode="out-in">
      <Contacts
        v-if="showContacts"
        @close="showContacts = !showContacts"
        v-click-outside="hide"
      />
    </transition>

    <div class="menu" @click="open = !open">
      <img
        class="img__w"
        src="@/assets/img/menu.svg"
        :class="darkModeClass"
        v-if="!open"
        alt="logo"
      />
      <img
        class="img__d"
        src="@/assets/img/menu-orange.svg"
        :class="darkModeClass"
        v-if="!open"
        alt="logo"
      />

      <transition name="slide-fade" mode="out-in">
        <MobileMenu v-if="open" />
      </transition>
    </div>
  </header>
</template>

<script>
import Contacts from "../views/Contacts.vue";
import MobileMenu from "../components/MobileMenu.vue";
import vClickOutside from "v-click-outside";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    Contacts,
    MobileMenu,
  },
  props: ["toggle"],
  data() {
    return {
      showContacts: false,
      open: false,
      active: false,
      isMobile: false,
    };
  },
  methods: {
    close() {
      this.showContacts = true;
    },
    hide() {
      this.showContacts = false;
    },
  },
  mounted() {
    this.isMobile = window.matchMedia("(max-width: 1140px)").matches;

    window.addEventListener("resize", () => {
      this.isMobile = window.matchMedia("(max-width: 1140px)").matches;
    });
  },
  watch: {
    isMobile() {
      if (!this.isMobile) {
        this.open = false;
      }
    },
    open() {
      if (this.open) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
    },
    $route() {
      // this.open = false;
    },
  },
  computed: {
    darkModeClass() {
      return { dark: this.$route.meta?.darkMode };
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/base.scss";
.hdr {
  // max-width: 1920px;
  margin: 0 auto;
  font-weight: 600;
  z-index: 20;
  position: relative;

  .hdr__nv {
    height: 47.56px;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    .nav__el {
      padding: 20px;

      .sp {
        font: normal normal bold 12px/13px Ubuntu;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        @include tr();
      }

      &.router-link-exact-active {
        .sp {
          padding-bottom: 5px;
          border-bottom: 2px solid #ed8300;
        }
      }

      &:hover {
        .sp {
          padding-bottom: 5px;
          border-bottom: 2px solid #ed8300;
          @include tr();
        }
      }
    }
    @media all and (max-width: 1140px) {
      .nav__el {
        display: none;
      }
    }

    .nav__el--img {
      padding-left: 20px;
      padding-right: 20px;
      .white__logo {
        display: none;
        @include transition(0.4s);

        &.dark {
          display: initial;
        }
        &:hover {
          transform: scale(1.1);
        }
      }
      @media all and (max-width: 1140px) {
        .white__logo {
          padding-top: 15px;
        }
      }

      .dark__logo {
        @include transition(0.4s);
        &.dark {
          display: none;
        }
        &:hover {
          transform: scale(1.1);
        }
      }

      @media all and (max-width: 1140px) {
        .dark__logo {
          padding-top: 15px;
        }
      }
    }
  }

  @media all and (max-width: 1140px) {
    .hdr__nv {
      display: initial;
      // &.hdr__active {
      //   display: initial;
      // }
    }
  }

  .menu {
    display: none;
    cursor: pointer;
  }

  @media all and (max-width: 400px) {
    .menu {
      display: flex;
      position: absolute;
      right: 25px;
      top: 30px;
    }
  }

  @media all and (max-width: 1140px) {
    .menu {
      display: block;
      position: absolute;
      right: 25px;
      top: 30px;

      .img__w {
        display: initial;
        &.dark {
          display: none;
        }
      }

      .img__d {
        display: none;
        &.dark {
          display: initial;
        }
      }
    }
  }

  &.dark {
    .nav__el {
      color: #ffffff;
    }
  }
}

/***TRANSITION ***/

//fade

.fade-enter {
  opacity: 0;
}
.fade-enter-active,
fade-leave-active {
  transition: opacity 0.8s ease-in;
}
.fade-leave-to {
  opacity: 0;
}
</style>
