<template>
  <div class="home__container">
    <div class="bgr__mobile"></div>
    <div class="home__main">
      <div class="home__main__el">
        <div class="home__main__desc">
          <img src="../assets/img/cdev.svg" alt="logo" />
          <h1>Строително-инвестиционно дружество</h1>
          <p>
            Ние се занимаваме с проучване, комплексно проектиране и строителство
            на административни и жилищни сгради.
          </p>
        </div>
      </div>
      <div class="hr__line"></div>
      <div class="home__main__img">
        <div class="bgr__big"></div>
        <img src="../assets/img/house.png" alt="house" />
      </div>
    </div>
    <HomeElement />
    <div class="bgr__sml"></div>
  </div>
</template>

<script>
import HomeElement from "../components/HomeElement.vue";

export default {
  components: {
    HomeElement,
  },
};
</script>


<style lang="scss">
@import "@/scss/base.scss";
.home__container {
  margin-top: -50px;
  position: relative;

  @include noselect();

  .bgr__mobile {
    display: none;
  }

  .home__main {
    display: flex;
    margin-bottom: 60px;
    padding-left: 157px;
    justify-content: space-between;

    .home__main__el {
      display: flex;
      flex-direction: column;
      flex-basis: 51%;

      .home__main__desc {
        padding-top: 120px;

        h1 {
          padding-bottom: 10px;
          font: normal normal bold 46px/48px Playfair Display;
          line-height: 0.8;
          margin-top: -15px;
          width: 100%;
        }

        p {
          color: #636363;
          font: normal normal normal 18px/27px Ubuntu;
          line-height: 1.2;
          padding-top: 5px;
          width: 85%;
        }

        @media all and (max-width: 480px) {
          h1 {
            font-size: 36px;
          }
        }
        img {
          width: 52%;
        }
      }
      @media all and (max-width: 1140px) {
        .home__main__desc {
          padding-top: 20px;

          p {
            width: 100%;
          }
        }
      }
    }

    .hr__line {
      background-color: #ffbc29;
      width: 70px;
      height: 2px;
      position: absolute;
      left: 0;
      top: 65%;
    }

    @media all and (max-width: 1140px) {
      .hr__line {
        display: none;
      }
    }

    .home__main__img {
      position: relative;

      .bgr__big {
        background: url("../assets/img/c1-b.svg");
        background-repeat: no-repeat;
        width: 43%;
        height: 100%;
        z-index: 2;
        position: absolute;
        right: 0;
      }

      @media all and (max-width: 1140px) {
        .bgr__big {
          display: none;
        }
      }

      img {
        display: block;
        margin-right: 75px;
        width: 100%;
      }
    }

    @media all and (max-width: 1140px) {
      .home__main__img {
        position: initial;

        img {
          display: block;
          margin-right: 0;
          width: 100%;
          height: auto;
        }
      }
    }
  }

  @media all and (max-width: 1140px) {
    .home__main {
      display: flex;
      flex-direction: column;
      height: auto;
      padding-left: 0;

      .home__main__el {
        width: 100%;
        padding: 20px;
        margin: 0 auto;
      }
    }
  }

  @media all and (max-width: 1900px) {
    .bgr__sml {
      display: block;
      background: url("../assets/img/c2-s.svg");
      background-repeat: no-repeat;
      width: 100%;
      height: 27%;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(0%, 60%);
      z-index: -1;
    }
  }

  @media all and (max-width: 1140px) {
    .bgr__sml {
      display: none;
    }
  }
}

@media all and (max-width: 1140px) {
  .home__container {
    margin-top: 0;
    height: auto;

    .bgr__mobile {
      display: block;
      background: url("../assets/img/c1-b.svg");
      background-repeat: no-repeat;
      width: 37%;
      height: 100%;
      position: absolute;
      right: 0;
      top: -66px;
      z-index: 2;
    }
  }
}
</style>
