
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CurrentProject from '../views/CurrentProject.vue'
import Map from '../views/Map.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/current-project',
    name: 'currentProject',
    component: CurrentProject
  },
  {
    path: '/map',
    name: 'map',
    component: Map,
    meta: {
      darkMode: true,
    },
  },
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },

})

export default router