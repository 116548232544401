<template>
  <div class="mob__container">
    <a class="mob__back">
      <img src="../assets/img/back.svg" alt="icon" />
    </a>
    <div class="menu__mob">
      <HomeElement />
    </div>
  </div>
</template>

<script>
import HomeElement from "../components/HomeElement.vue";

export default {
  data() {
    return {};
  },
  components: {
    HomeElement,
  },
};
</script>

<style lang="scss">
@import "@/scss/base.scss";
.mob__container {
  // background-color: #FFFFFF;
  // height: 100vh;
  // padding: 0 20px;
  // transform: translate(3%, -5%);
  // z-index: 20;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  width: 100%;
  //@include transition(1s);

  .mob__back {
    padding-top: 20px;
    padding-left: 20px;
    // padding-top: 20px;
    // position: absolute;
    // transform: translate(45%, 29%);

    img {
      background: #ffbc29;
      border-radius: 2px;
      height: 50px;
      padding: 8px;
      width: 50px;
    }
  }
  .menu__mob {
    padding-top: 50px;
    //padding-top: 350px;
  }
}
</style>
