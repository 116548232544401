<template>
  <div class="div__container">
    <div class="div__header">
      <div class="div__title">
        <h2>Текущ проект</h2>
        <div>
          <span></span>
          <p>Урегулиран парцел до язовир Искър, вилна зона "Мечката"</p>
        </div>
      </div>
    </div>

    <div class="div__content">
      <div class="map"></div>

      <div class="desc">
        <div class="desc__circles">
          <span><span>УРЕГУЛИРАН ПАРЦЕЛ</span></span>
          <span><span>НА 1 ЧАС ПЪТ ОТ СОФИЯ</span></span>
          <span><span>НА 15 МИН. ОТ БОРОВЕЦ</span></span>
          <span><span>НА 10 МИН. ОТ САМОКОВ</span></span>
          <span><span>НА 5 МИН ОТ ЯЗ. ИСКЪР</span></span>
        </div>

        <div class="desc__params">
          <section>
            <h2>ПАРАМЕТРИ НА ЗАСТРОЯВАНЕ</h2>
            <p>• Плътност – 40%</p>
            <p>• КИНТ – 0.8</p>
            <p>• Озеленяване – 50%</p>
            <p>• Височина – 7м</p>
            <p>• Етажност – 2</p>
          </section>

          <section>
            <h2>ХАРАКТЕРИСТИКИ НА ИМОТА</h2>
            <p>
              • Тип на имота: Парцели в регулация, Земи, Парцели за инвестиция
            </p>
            <p>• Обща площ: 3131.00 м2 (чиста площ плюс общи части)</p>
          </section>

          <section class="padding">
            <h2>ОПИСАНИЕ НА ИМОТА</h2>
            <p>
              Предлагаме на вашето внимание урегулиран поземлен имот с изработен
              ПУП и лице на улица. Равен и слънчев парцел, разположен сред вили
              и къщи с постоянно обитаване във в.з. Мечката, местност „Татарски
              брод“, на 10 км от Самоков и на 20 км от ски-курорта Боровец.
            </p>
            <p>
              До имота има ток, възможно е да свързване с канализация и
              водоснабдяване.
            </p>
            <p>
              Парцелът се намира на 200 м от язовир Искър, пътят е с целогодишен
              достъп. Разположението е между река, гора, планина и най-големия
              язовир в страната.
            </p>
            <p>
              Парцелът е разположен в рекреационна устройствена зона на вилен
              отдих – Ов, респективно, имотът е подходящ за изграждането на
              вилна сграда, която може да служи за лично ползване, отдаване под
              наем или за развитието на малък семеен бизнес.
            </p>
            <p>
              Регионът благоприятства еко туризъм, лов и риболов, спорт на
              открито, организирани преходи и пикници и др.
            </p>
          </section>

          <a
            class="google__map"
            href="https://goo.gl/maps/RAn4HvKCEkqcYuxE8"
            target="_blank"
            ><h2>ПРЕГЛЕД В GOOGLE MAPS</h2></a
          >
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {};
</script>

<style lang="scss">
@import "@/scss/base.scss";
.div__container {
  margin-top: -50px;

  .div__header {
    background-color: #fff2d4;
    height: 120px;
    position: relative;

    .div__title {
      display: flex;
      flex-direction: column;
      padding-left: 157px;
      position: absolute;
      top: 28%;
      width: 50%;

      h2 {
        color: #3f3f3f;
        font: normal normal bold 32px/58px Ubuntu;
        letter-spacing: 0px;
        opacity: 1;
        padding-bottom: 20px;
        text-align: left;
        white-space: nowrap;
      }

      div {
        display: flex;
        position: absolute;
        top: 70%;
        width: 100%;
        p {
          color: #636363;
          font: normal normal normal 18px/22px Ubuntu;
          letter-spacing: 0px;
          opacity: 1;
          padding-left: 10px;
          text-align: left;
          width: 100%;
        }

        span {
          background-color: #ffbc29;
          display: block;
          height: 45px;
          width: 6px;
        }
      }
    }
    @media all and (max-width: 1140px) {
      .div__title {
        padding-left: 20px;
      }
    }

    @media all and (max-width: 480px) {
      .div__title {
        width: 90%;
      }
    }
  }

  @media all and (max-width: 1140px) {
    .div__header {
      height: 180px;
      margin-top: -20px;
    }
  }

  .div__content {
    display: flex;
    gap: 20px;
    margin-left: 157px;
    padding: 50px 0;

    .map {
      background-image: url("../assets/img/map.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top;
      width: 40%;
      @include transition(0.3s);

      &:hover {
        background-image: url("../assets/img/map-hover.svg");
        @include transition(0.3s);
      }
    }

    @media all and (max-width: 1140px) {
      .map {
        background-position: center;
        height: 500px;
        width: 100%;
      }
    }

    @media all and (max-width: 768px) {
      .map {
        height: 400px;
      }
    }

    @media all and (max-width: 400px) {
      .map {
        height: 250px;
      }
    }

    .desc {
      display: flex;
      width: 60%;
      gap: 20px;
      padding-right: 134px;

      .desc__circles {
        display: flex;
        flex-direction: column;
        justify-content: center;

        > span {
          background-color: #2e3d51;
          border-radius: 50%;
          color: #ffffff;
          display: flex;
          height: 110px;
          margin-bottom: 10px;
          position: relative;
          width: 110px;

          > span {
            font-size: 16px;
            font-weight: 500;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            position: absolute;
            width: 100%;
          }
        }
      }

      @media all and (max-width: 1140px) {
        .desc__circles {
          display: flex;
          flex-direction: initial;
          gap: 10px;

             > span {
          background-color: #2e3d51;
          border-radius: 50%;
          color: #ffffff;
          display: flex;
          max-height: 17vw;
          //margin-bottom: 10px;
          position: relative;
          max-width: 17vw;

          > span {
            font-size: 15px;
            width: fit-content;
          }
        }
        }
      }

      @media all and (max-width: 660px) {
        .desc__circles {
          span {
            > span {
              font-size: 2.3vw;
            }
          }
        }
      }

      .desc__params {
        display: flex;
        flex-direction: column;

        section {
          padding-bottom: 20px;
          h2 {
            font: normal normal bold 15px/22px Ubuntu;
            color: #2e3d51;
          }

          p {
            color: #2e3d51;
            font: normal normal normal 15px/22px Ubuntu;
          }
        }

        .padding {
          p {
            padding-bottom: 8px;
          }
        }

        .google__map {
          h2 {
            color: #2e3d51;
            border-bottom: 2px solid #2e3d51;
            display: inline-block;
            font: normal normal bold 15px/22px Ubuntu;
            line-height: 1;
          }
        }
      }
    }
    @media all and (max-width: 1140px) {
      .desc__params{
        padding: 0 20px;
      }
      .desc {
        display: initial;
        padding-right: 0;
        width: 100%;
      }
    }
  }

  @media all and (max-width: 1140px) {
    .div__content {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
    }
  }
}

@media all and (max-width: 1140px) {
  .div__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
}
</style>