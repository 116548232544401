<template>
  <div class="div__cnt">
    <div class="div__hdr">
      <div class="div__title">
        <h2>Пътна карта</h2>
        <div>
          <span></span>
          <p>Пътна карта с изпълнените и предстоящи етапи по проекта</p>
        </div>
      </div>

      <div class="div__back">
        <img
          class="left"
          src="@/assets/img/left.svg"
          alt=""
          @click="swipeLeft()"
        />

        <img
          class="right"
          src="@/assets/img/right.svg"
          alt=""
          @click="swipeRight()"
        />
      </div>
    </div>

    <div class="div__map">
      <div
        class="box-scroll"
        @mousewheel="draggAndScroll(), scrolling()"
        ref="contentEl"
      >
        <img
          @mousewheel="progressBarScroll()"
          class="img__main"
          src="../assets/img/path-map.svg"
          alt="img"
        />
      </div>

      <div class="progress">
        <progress id="progressbar" value="" max="100"></progress>
      </div>

      <div class="box-scroll-small" @mousewheel="draggAndScroll()">
        <img
          class="img__main-small"
          src="../assets/img/timeline.svg"
          alt="img"
        />
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {};
  },
  methods: {
    swipeLeft() {
      const content = document.querySelector(".box-scroll");
      content.scrollLeft -= 1500;
      this.isSVisibleFrst = true;
      this.progressBarScroll();
    },
    swipeRight() {
      const content = document.querySelector(".box-scroll");
      content.scrollLeft += 1500;
      this.isSVisibleFrst = false;
      this.progressBarScroll();
    },
    scrolling() {
      const mouseWheel = document.querySelector(".box-scroll");
      mouseWheel.addEventListener("wheel", function (e) {
        const race = 30; // How many pixels to scroll
        if (e.deltaY > 0)
          // Scroll right
          mouseWheel.scrollLeft += race;
        // Scroll left
        else mouseWheel.scrollLeft -= race;
        e.preventDefault();
      });
    },
    draggAndScroll() {
      const slider = document.querySelector(".box-scroll");
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = x - startX;
        slider.scrollLeft = scrollLeft - walk;
        this.progressBarScroll();
      });
    },
    progressBarScroll() {
      let contentEl = this.$refs.contentEl;
      let fullWidth = contentEl.scrollWidth;
      let viewWidth = contentEl.offsetWidth;
      let scrollPosition = contentEl.scrollLeft;
      let progressValue = ((scrollPosition + viewWidth) / fullWidth) * 100;

      let progressBar = document.querySelector("#progressbar");
      progressBar.setAttribute("value", progressValue);

    },
  },

};
</script>

<style lang="scss">
@import "@/scss/base.scss";
.progress {
  max-width: 32vw;
  background: none;
  border: 0;
  height: 10px;
  margin: 0 auto;

  progress {
    position: relative;
    height: 100%;
    width: 100%;
    border: 0;
    background-color: transparent;
  }

  progress::-moz-progress-bar {
    background-color: rgba(white, 0.15);
  }
  progress::-webkit-progress-bar {
    background-color: transparent;
  }
  progress::-webkit-progress-value {
    background-color: rgba(yellow, 0.15);
  }
}
.div__cnt {
  margin-top: -50px;
  overflow: hidden;
  .div__hdr {
    background-color: #2e3d51;
    height: 120px;
    position: relative;

    .div__title {
      display: flex;
      flex-direction: column;
      padding-left: 157px;
      position: absolute;
      top: 28%;
      width: 50%;

      h2 {
        color: #ffbc29;
        font: normal normal bold 32px/58px Ubuntu;
        letter-spacing: 0px;
        padding-bottom: 20px;
        opacity: 1;
        text-align: left;
      }

      div {
        display: flex;
        position: absolute;
        top: 70%;
        width: 100%;
        p {
          color: #c78c0c;
          font: normal normal normal 18px/22px Ubuntu;
          letter-spacing: 0px;
          opacity: 1;
          padding-left: 10px;
          text-align: left;
          width: 100%;
        }

        span {
          background-color: #ffbc29;
          display: block;
          height: 45px;
          width: 6px;
        }
      }
    }

    @media all and (max-width: 1140px) {
      .div__title {
        padding-left: 20px;
        width: 90%;
        margin-left: 0;
      }
    }

    .div__back {
      display: flex;
      gap: 80px;
      left: 80%;
      position: absolute;
      top: 65%;
      width: 50%;

      .left {
        cursor: pointer;
        opacity: 0.3;
        @include tr();

        &:hover {
          opacity: 1;
        }
      }

      .right {
        cursor: pointer;
        opacity: 0.3;
        @include tr();

        &:hover {
          opacity: 1;
        }
      }
    }
    @media all and (max-width: 1140px) {
      .div__back {
        display: none;
      }
    }
  }

  @media all and (max-width: 1140px) {
    .div__hdr {
      height: 180px;
    }
  }

  // MAP

  .div__map {
    background-color: #27303c;
    padding: 50px 0;
    width: 100%;
    height: 100%;

    .box-scroll {
      cursor: pointer;
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      overflow: auto;
      scrollbar-width: none; /* Firefox */
      //scroll-behavior: smooth;
      -ms-overflow-style: none; /* IE and Edge */
    }

    .box-scroll::-webkit-scrollbar {
      display: none;
    }

    .img__main {
      display: block;
      padding-bottom: 20px;
      margin: 0 auto;
    }

    .box-scroll-small {
      cursor: pointer;
      display: flex;
      flex-wrap: nowrap;
      height: 100px;
      justify-content: center;
      overflow: auto;
      position: relative;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      width: 100%;

      .border {
        border: 2px solid #ffbc29;
        width: 200px;
      }

      .img__main-small {
        display: block;
        padding: 10px;
      }
    }
    .box-scroll-small::-webkit-scrollbar {
      display: none;
    }
  }
}

@media all and (max-width: 1140px) {
  .div__cnt {
    margin-top: -66px;
    overflow: auto;
  }
}
</style>