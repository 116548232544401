<template>
  <div class="home__elements">
    <router-link class="home__elm" :to="{ path: 'current-project' }">
      <div>
        <h2>Текущ проект</h2>
        <div class="inside">
          <span></span>
          <p>Урегулиран парцел до язовир Искър, вилна зона "Мечката"</p>
        </div>
      </div>
      <img src="../assets/img/project.svg" alt="map" />
    </router-link>

    <router-link class="home__elm" :to="{ path: 'map' }">
      <div>
        <h2>Пътна карта</h2>
        <div class="inside">
          <span></span>
          <p>Пътна карта с изпълнените и предстоящи етапи по проекта</p>
        </div>
      </div>
      <img src="../assets/img/path.svg" alt="map" />
    </router-link>

    <a class="home__elm" @click="showContacts = !showContacts">
      <div>
        <h2>Контакти</h2>
        <div class="inside">
          <span></span>
          <p>Свържете се с нас за повече информация</p>
        </div>
      </div>
      <img src="../assets/img/contacts.svg" alt="map" />
    </a>

    <transition name="slide-fade" mode="out-in">
      <Contacts
        v-if="showContacts"
        @close="showContacts = false"
        v-click-outside="hide"
      />
    </transition>
  </div>
</template>

<script>
import Contacts from "../views/Contacts.vue";
import vClickOutside from "v-click-outside";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: [],
  components: {
    Contacts,
  },
  data() {
    return {
      showContacts: false,
    };
  },
  methods: {
    close() {
      this.showContacts = true;
    },
    hide() {
      this.showContacts = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/base.scss";
.home__elements {
  display: flex;
  gap: 23px;
  justify-content: flex-start;
  margin-left: 157px;

  .home__elm {
    display: flex;
    flex-basis: 30%;
    background-color: #fff2d4;
    border-radius: 5px;
    cursor: pointer;
    @include transition(0.4s);

    &:hover {
      transform: scale(1.025);
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      h2 {
        color: #3f3f3f;
        font: normal normal bold 32px/58px Ubuntu;
        padding-left: 20px;
        padding-top: 10px;
        text-align: left;
        letter-spacing: 0px;
        opacity: 1;
      }

      @media all and (max-width: 480px) {
        h2 {
          padding-top: 50px;
          font: normal normal bold 24px/20px Ubuntu;
        }
      }

      .inside {
        display: flex;
        flex-direction: initial;
        p {
          width: 90%;
          text-align: left;
          font: normal normal normal 18px/22px Ubuntu;
          letter-spacing: 0px;
          color: #636363;
          opacity: 1;
        }

        @media all and (max-width: 480px) {
          p {
            font: normal normal normal 13px/15px Ubuntu;
          }
        }

        > span {
          display: block;
          margin-left: 20px;
          width: 6px;
          height: 45px;
          background-color: #ffbc29;
        }
      }
    }

    img {
      display: block;
      width: 110px;
      height: 130px;
      margin: 10px;
    }

    @media all and (max-width: 480px) {
      img {
        display: block;
        width: 75px;
        height: 130px;
        margin: 10px;
      }
    }
  }
}

@media all and (max-width: 1140px) {
  .home__elements {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin: 0 auto;

    :nth-child(3){
      margin-bottom: 23px;
    }
  }
}

/***TRANSITION ***/

.fade-enter {
  opacity: 0;
}
.fade-enter-active,
fade-leave-active {
  transition: opacity 0.8s ease-in;
}
.fade-leave-to {
  opacity: 0;
}

//slide

.slide-fade-enter {
  transform: translateX(30px);
  opacity: 0;
}
.slide-fade-enter-active,
slide-fade-leave-active {
  transition: all 0.8s ease;
}

.slide-fade-leave-to {
  transform: translateX(-30px);
  opacity: 0;
}
</style>