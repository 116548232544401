<template>
  <div id="app">
    <Header v-if="$route.name !== 'contacts'" />
    <transition name="slide" mode="out-in">
      <router-view />
    </transition>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import Header from "./components/Header";

export default {
  name: "App",
  components: {
    Header,
  },
};
</script>

<style lang="scss">
@import "@/scss/reset.scss";
@import "~vue-snotify/styles/dark";
@font-face {
  font-family: "Ubuntu";
  src: url("./assets/fonts/Ubuntu.woff2") format("woff2"),
    url("./assets/fonts/Ubuntu.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu";
  src: url("./assets/fonts/Ubuntu-Bold.woff2") format("woff2"),
    url("./assets/fonts/Ubuntu-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Playfair Display";
  src: url("./assets/fonts/PlayfairDisplay-Bold.woff2") format("woff2"),
    url("./assets/fonts/PlayfairDisplay-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

#app {
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "IBM Plex Serif", Helvetica, Arial, sans-serif;
  font-weight: 500;
}

h2 {
  font-size: 36px;
  line-height: 40px;
}

.slide-enter-active {
  animation: slide-in 300ms ease-out forwards;
}

.slide-leave-active {
  animation: slide-out 300ms ease-out forwards;
}

@keyframes slide-in {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(-30px);
    opacity: 0;
  }
}
</style>
